import React from 'react';
import './Hero.scss';
import heroImg from '../../assets/img/heroImg.webp';
import Button from '../../components/button/Button';
import { useInView } from 'react-intersection-observer';

function Hero() {

  const{ ref,inView } = useInView({
    threshold:0.5,
  });

  return (
    <div className="hero-wrapper">
        <div ref={ref} className={`hero-content-wrapper ${inView ? 'hero-content-wrapper-active': ''}`}>
          <div className="hero-text-block">
            <div className="hero-capture">
              Whole company in your hand
            </div>
            <div className="hero-description">
              Be on the one line with your for deskless employees. 
              Coomunicate. Assign tasks. Notify the news. Manage the employees. 
              All-in-one solution.
            </div>
            <div className="hero-button">
              <Button text={'Request demo'} />
            </div>
          </div>
          <div className="hero-img-block">
            <img src={heroImg} alt="phone" />
          </div>
        </div>
    </div>
  );
}

export default Hero;