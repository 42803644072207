import React from 'react';
import './Features.scss';
import featuresImg1 from '../../assets/img/featuresImg1.svg';
import featuresImg2 from '../../assets/img/featuresImg2.svg';
import featuresImg3 from '../../assets/img/featuresImg3.svg';
import featuresImg4 from '../../assets/img/featuresImg4.svg';
import { useInView } from 'react-intersection-observer';

function Features() {

  const{ ref,inView } = useInView({
    threshold:0,
  });

  const featuresData =[
    {
      img:featuresImg1,
      capture:"News",
      description:"Our employee app ensures real-time delivery of relevant information to employees, keeping them up to date and informed."
    },
    {
      img:featuresImg2,
      capture:"Chat",
      description:"Enjoy seamless communication through individual and group chats, similar to a private messenger, with enterprise-level security and data protection in full compliance with GDPR."
    },
    {
      img:featuresImg3,
      capture:"Payroll",
      description:"Our mobile application empowers employees with complete transparency regarding their salaries, granting access to their entire employment history."
    },
    {
      img:featuresImg4,
      capture:"Tasks",
      description:"Empower your employees with the ability to receive and manage tasks effortlessly through our mobile application."
    },
  ];

  const featuresItem = featuresData.map((item) => (
    <div  key={item.capture} className={`item-card-wrapper`}>
      <div className="item-card-content">
        <div className="item-image">
          <img src={item.img} alt="featuresImg" />
        </div>
        <div className="features-item-capture">
          {item.capture}
        </div>
        <div className="features-item-description">
          {item.description}
        </div>
      </div>
    </div>
  ));

  return (
    <div id='Features' className="features-wrapper">
        <div className="features-content">
          <div className="features-capture">
            Features
          </div>
          <div ref={ref} className={`features-card-wrapper ${inView ? 'features-card-wrapper-active' : ''}`}>
            {featuresItem}
          </div>
        </div>
    </div>
  );
}

export default Features;