import React from 'react';
import './Button.scss';
import { Link } from 'react-router-dom';

function Button({text}) {
  return (
    <Link to={'https://calendly.com/denys-rabota-ps/30min?month=2023-06'} target='blank'><button>{text}</button> </Link>
     );
}

export default Button;