import React from 'react';
import './Partners.scss';
import partnersImg1 from '../../assets/img/partners1.svg';
import partnersImg2 from '../../assets/img/partners2.svg';
import partnersImg3 from '../../assets/img/partners3.svg';
import Slider from 'react-slick';
import "./slick.css";
import "./slick-theme.css";

function Partners() {

  const settings = {
    autoplay:true,
    autoplaySpeed:3000,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1026,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
            breakpoint: 718,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
    ]
  };
  const partersData = [
    {
      img:partnersImg1,
      link:'https://personnelsupport.pl/'
    },
    {
      img:partnersImg2,
      link:'https://accelpoint.com/'
    },
    {
      img:partnersImg3,
      link:'https://www.parp.gov.pl/component/grants/grants/poland-prize'
    }
  ];
  const partnersItem =partersData.map((item,index) => (
    <div key={index} className="slide-item">
      <div key={index} className="partners-item" >
        <a href={item.link} target='blank'><img src={item.img} alt=""/></a>
      </div>
    </div>
  ))
  return (
    <div id='Partners' className="partners-wrapper">
        <div className="parnters-content">
          <Slider {...settings}>
            {partnersItem}
          </Slider>
        </div>
    </div>
  );
}

export default Partners;