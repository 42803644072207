import React from 'react';
import './Header.scss';
import headerLogo from '../../assets/img/headerLogo.svg';
import Button from '../../components/button/Button';
import { HashLink} from 'react-router-hash-link';
import HeaderMob from '../../components/header-components/HeaderMob';
import { useState} from 'react';

function Header() {
  const navData = ['Partners','Features','Industries'];
  const [color,setColor] = useState(false);

  const changeBackground = () => {
    if(window.scrollY >= 60){
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener('scroll',changeBackground)

  const navItem = navData.map((item) => (
    <div key={item} className="nav-item">
      <HashLink to={`#${item}`} smooth>
        {item}
      </HashLink>
    </div>
  ));
  return (
    <div className={`header-wrapper ${color ? 'header-bg' : ''}`}>
      <div className="header-content-block">
        <div className="header-content-wrapper">
            <div className="header-white-cover">
                <div className="header-logo-block">
                  <div className="header-logo-img">
                    <img src={headerLogo} alt="" />
                  </div>
                  <div className="logo-text">
                    Job Integral
                  </div>
                </div>
              <div className="header-nav-block">
                {navItem}
              </div>
            </div>
          
            <div className="header-button-block">
              <Button text={'Request demo'}/>
            </div>
          </div>
          <HeaderMob/>
        </div>   
    </div>
  );
}

export default Header;