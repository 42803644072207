import React from 'react';
import './Industries.scss';
import industriesImg1 from '../../assets/img/industriesImg1.svg';
import industriesImg2 from '../../assets/img/industriesImg2.svg';
import industriesImg3 from '../../assets/img/industriesImg3.svg';
import industriesImg4 from '../../assets/img/industriesImg4.svg';
import Slider from 'react-slick';
import "../partners/slick.css";
import "../partners/slick-theme.css";
import '../partners/slick.css';
import '../partners/slick-theme.css';
import { useInView } from 'react-intersection-observer';

function Industries() {

  const{ ref,inView } = useInView({
    threshold:0,
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1026,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
            breakpoint: 718,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
    ]
  };

  const industriesData = [
    {
      img:industriesImg1,
      capture:"Manufacturing",
      description:"In the manufacturing sector, efficient communication between employers and deskless employees is crucial for ensuring smooth operations and maintaining productivity."
    },
    {
      img:industriesImg2,
      capture:"Warehouses",
      description:"Our software solution provides a centralized platform where warehouse employers can communicate important updates, share training materials, and address employee queries, leading to streamlined operations and improved employee engagement."
    },
    {
      img:industriesImg3,
      capture:"Logistics",
      description:"Deskless employees in logistics, such as delivery drivers, often operate remotely and face challenges in staying connected with their employers and receiving critical updates."
    },
    {
      img:industriesImg4,
      capture:"Retail",
      description:"Our software solution provides a mobile application that empowers retail employees to access important news, receive shift schedules, participate in training programs, and communicate with their employers, resulting in improved employee engagement and customer experience."
    },
  ];

  const industriesItem = industriesData.map((item) => (
    <div key={item.capture} className="industries-slide-item">
        <div key={item.capture} className="industries-card">
        <div className="industries-img">
          <img src={item.img} alt="industries" />
        </div>
        <div className="industries-item-capture">
          {item.capture}
        </div>
        <div className="industries-item-description">
          {item.description}
        </div>
      </div>
    </div>
    
  ));

  return (
    <div ref={ref} id='Industries' className={`industries-wrapper ${inView ? 'industries-wrapper-active' : ''}`}>
        <div className="industries-content">
          <div className="industries-capture">
            What industries it designed for
          </div>
          <div className="industries-card-wrapper">
            <Slider {...settings}>
              {industriesItem}
            </Slider>
          </div>
        </div>
    </div>
  );
}

export default Industries;