import React from 'react';
import './Footer.scss';
import headerLogo from '../../assets/img/headerLogo.svg';
import { HashLink} from 'react-router-hash-link';

function Footer() {

  const footerNavData = ['Partners','Features','Industries'];

  const footerNavItem = footerNavData.map((item) => (
    <div key={item} className="footer-nav-item">
      <HashLink to={`#${item}`} smooth>
        {item}
      </HashLink>
    </div>
  ));

  const footerContactsData =['ul. Aleja Jana Pawła II 11/915','00-828, Warsaw','NIP: 5273050301'];

  const footerContactItem = footerContactsData.map((item) => (
    <div key={item} className="contacts-item">
      {item}
    </div>
  ));

  return (
    <div className="footer-wrapper">
        <div className="footer-content">
          <div className="footer-logo-block">
            <div className="header-logo-img">
              <img src={headerLogo} alt="" />
            </div>
            <div className="logo-text">
              Job Integral
            </div>
          </div>
          <div className="footer-nav-block">
            {footerNavItem}
          </div>
          <div className="footer-contacts-block">
            {footerContactItem}
            <a href="mailto:hello@job-integral.pl">hello@job-integral.pl</a>
          </div>
        </div>
    </div>
  );
}

export default Footer;