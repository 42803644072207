import React from 'react';
import './Advertising.scss';
import advertisingImg from '../../assets/img/advertizingImg.webp';
import { useInView } from 'react-intersection-observer';

function Advertising() {

  const{ ref,inView } = useInView({
    threshold:0.5,
  });

  return (
    <div ref={ref} className={`advertising-wrapper ${inView ? 'advertising-wrapper-active' : ''}`}>
        <div className="advertising-content">
          <div className="advertising-capture">
            The whole company in one app
          </div>
          <div className="advertising-description">
            Unify your workforce with our powerful mobile app. 
            Connect, communicate, and collaborate seamlessly, 
            putting the whole company at your fingertips.
          </div>
          <div className="advertising-image">
            <img src={advertisingImg} alt="advertising" />
          </div>
        </div>
    </div>
  );
}

export default Advertising;