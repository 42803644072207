import React from 'react';
import './HeaderMob.scss';
import headerLogo from '../../assets/img/headerLogo.svg';
import burger from '../../assets/img/burger.png';
import close from '../../assets/img/close.png';
import { HashLink} from 'react-router-hash-link';
import OutsideHook from '../ousideHook/OutsideHook';
import { useEffect } from 'react';
import Button from '../button/Button';

function HeaderMob() {

  const [ref ,isShow,setIsShow] = OutsideHook(false);

  const navData = ['Partners','Features','Industries'];
  const navItem = navData.map((item) => (

    <div key={item} className="nav-item">
      <HashLink to={`#${item}`} smooth>
        {item}
      </HashLink>

    </div>
  ));

  const dropMenu = () => {
    setIsShow(!isShow);
  }
  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isShow ? "hidden" : "auto";
    }
  }, [isShow]);

  return (
    <div ref={ref} className="header-mob-wrapper">
        <div className="header-mob-wrapper-content">
          <div className="header-logo-block">
            <div className="header-logo-img">
              <img src={headerLogo} alt="" />
            </div>
            <div className="logo-text">
              Job Integral
            </div>
          </div>
          <div className="burger" onClick={()=>{
              dropMenu();
              }}>
            <img src={isShow ? close : burger} alt="burger" />
          </div>
        </div>
        {isShow && 
          <div className="drop-content" onClick={()=>{
            dropMenu();
            }}>
              <div className="drop-nav-block">
                {navItem}
              </div>
              <div className="header-button-block">
                <Button text={'Request demo'}/>
              </div>
          </div>    
        } 
    </div>
     );
}

export default HeaderMob;