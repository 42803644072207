import React from 'react';
import './App.scss';
import Advertising from '../advertising/Advertising';
import Features from '../features/Features';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import Hero from '../hero/Hero';
import Industries from '../industries/Industries';
import Partners from '../partners/Partners';
import circle from '../../assets/img/yellowCircle.png';
import {Helmet, HelmetProvider } from 'react-helmet-async';


function App() {
  return (
    <HelmetProvider>
      <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Job Integral - Whole company in your hand</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
        <div className="circle"><img src={circle} alt="circle"/></div>
        <Header/>
        <Hero/>
        <Partners/>
        <Advertising/>
        <Features/>
        <Industries/>
        <Footer/>
      </div>
    </HelmetProvider>
  );
}

export default App;
